import React from 'react'
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ArgonButton from "components/ArgonButton";
import { formatTimestamp, countryToLocale } from 'blikol-layouts/clinic/parsers/dateFormatParser';
import PropTypes from 'prop-types';
import Spinner from 'blikol-layouts/components/Spinner';
import "utils/i18n"
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';


function PatientDiagnosis({ onSessionHistoryClick, patientData }) {
    const userCountry = useSelector((state) => state.auth?.user?.country);
    const { t } = useTranslation()

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }

    const frequencyFormat = (value) => {
        return `${value} Hz`;
    };

    return (
        <Grid item xs={12} sm={12} p={3}>
            {
                patientData?.sessions.map((session, index) => (
                    <Card
                        key={index}
                        sx={{
                            padding: '30px',
                            gap: '10px',
                            borderRadius: '20px',
                            background: '#F4F2F2',
                            marginBottom: "25px",
                        }}
                    >
                        <ArgonBox
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}
                        >
                            <ArgonBox
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: '16px',
                                }}
                            >
                                <ArgonTypography
                                    sx={{
                                        color: '#172B4D',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '150%',

                                    }}
                                >
                                    {formatTimestamp(session.created_at, countryToLocale[userCountry])}
                                </ArgonTypography>
                                <ArgonBox sx={{ display: "flex", flexDirection: "row" }}>
                                    <ArgonTypography sx={fieldNameStyle}>
                                        {t("Frequency")}:
                                        <span className="frequency-value-session" >
                                            {frequencyFormat(session.frequency)}
                                        </span>
                                    </ArgonTypography>

                                </ArgonBox>

                                <ArgonBox>
                                    <ArgonTypography sx={fieldNameStyle}>
                                        {t("Note")}
                                    </ArgonTypography>
                                    <ArgonTypography sx={fieldValueStyle} my={1}>
                                        {session.note}
                                    </ArgonTypography>
                                </ArgonBox>
                            </ArgonBox>
                            <ArgonBox>
                                <ArgonButton
                                    variant="text"
                                    color="info"
                                    sx={{ fontWeight: 600 }}
                                    onClick={() => { onSessionHistoryClick(session.id, session.duration, session.frequency); }}
                                >
                                    {t("Session History")}
                                </ArgonButton>
                            </ArgonBox>
                        </ArgonBox>
                    </Card>
                ))
            }

        </Grid>
    )
}

PatientDiagnosis.propTypes = {
    onSessionHistoryClick: PropTypes.func.isRequired,
    patientData: PropTypes.object

};

export default PatientDiagnosis