import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import useSettingService from "service-hooks/useSettingService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import FormHelperText from "@mui/material/FormHelperText";

function Session() {
  const settingHookService = useSettingService();
  const [formData, setFormData] = useState({
    length: 0,
    stop_duration: 0,
  });
  const [errors, setErrors] = useState({
    length: "",
    stop_duration: "",
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    settingHookService.settings().then((result) => {
      if (result) {
        const sessionData = result.data.session;
        setFormData({
          length: sessionData.length || 0,
          stop_duration: sessionData.stop_duration || 0,
        });
      } else {
        console.log("Unable to fetch settings");
      }
    });
  }, []);

  useEffect(() => {
    const isFormInvalid =
      !formData.length || !formData.stop_duration ||
      formData.length <= 0 || formData.stop_duration < 1 || formData.stop_duration > 100;
    setIsButtonDisabled(isFormInvalid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const intValue = parseInt(value, 10);

    let error = "";
    if (name === "length") {
      if (!value) {
        error = "Session Duration is required";
      } else if (intValue <= 0) {
        error = "Session Duration must be greater than 0";
      }
    } else if (name === "stop_duration") {
      if (!value) {
        error = "Completion Rate is required";
      } else if (intValue < 1 || intValue > 100) {
        error = "Completion Rate must be between 1 and 100";
      }
    }

    setFormData({
      ...formData,
      [name]: intValue,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = () => {
    let valid = true;
    const newErrors = {
      length: "",
      stop_duration: "",
    };

    if (!formData.length) {
      newErrors.length = "Session Duration is required";
      valid = false;
    } else if (parseInt(formData.length, 10) <= 0) {
      newErrors.length = "Session Duration must be greater than 0";
      valid = false;
    }

    if (!formData.stop_duration) {
      newErrors.stop_duration = "Completion Rate is required";
      valid = false;
    } else if (
      parseInt(formData.stop_duration, 10) < 1 ||
      parseInt(formData.stop_duration, 10) > 100
    ) {
      newErrors.stop_duration = "Completion Rate must be between 1 and 100";
      valid = false;
    }
    setErrors(newErrors);

    if (valid) {
      settingHookService
        .update({ session: formData })
        .then(() => {
          toast.success(`Session settings updated successfully.`);
        })
        .catch((error) => {
          toast.error(error.message || "Failed to update session settings");
        });
    } else {
      toast.error("Please fix the errors in the form");
    }
  };

  return (
    <Grid item xs={10}>
      <Card sx={{ overflow: "hidden" }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Session Settings</ArgonTypography>
        </ArgonBox>
        <ArgonBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Session Duration in min"
                  placeholder="e.g 30 mins"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  required={true}
                  type="number"
                  error={Boolean(errors.length)}
                />
                {errors.length && (
                  <FormHelperText
                    sx={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '7px',
                    }}
                  >
                    {errors.length}
                  </FormHelperText>
                )}

              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Completion Rate in %"
                  placeholder="e.g 50"
                  name="stop_duration"
                  value={formData.stop_duration}
                  onChange={handleChange}
                  required={true}
                  type="number"
                  error={Boolean(errors.stop_duration)}
                  helperText={errors.stop_duration}
                />
                {errors.stop_duration && (
                  <FormHelperText
                    sx={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '7px',
                    }}
                  >
                    {errors.stop_duration}
                  </FormHelperText>
                )}

              </Grid>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
      <ArgonBox mt={3}>
        <ArgonButton onClick={handleSubmit} color="info" disabled={isButtonDisabled}>
          Update
        </ArgonButton>
      </ArgonBox>
    </Grid>
  );
}

export default Session;
