/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
// import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from "context";

// Custom styles for CustomCreateableSelect
import styles from "components/CustomCreateableSelect/styles";

const CustomCreateableSelect = forwardRef(({ size, error, success, ...rest }, ref) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const { light } = colors;

    const handleKeyDown = (event) => {
        const input = event.target;
        if (event.key === 'Home') {
            input.setSelectionRange(0, 0);
        } else if (event.key === 'End') {
            const endPosition = input.value.length;
            input.setSelectionRange(endPosition, endPosition);
        }
    };

    return (
        <CreatableSelect
            onKeyDown={handleKeyDown}
            ref={ref}
            styles={styles(size, error, success, darkMode)}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: light.main,
                    primary: light.main,
                },
            })}
            {...rest}

        />
    );
});

// Setting default values for the props of CustomCreateableSelect
CustomCreateableSelect.defaultProps = {
    size: "medium",
    error: false,
    success: false,

};

// Typechecking props for the CustomCreateableSelect
CustomCreateableSelect.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    error: PropTypes.bool,
    success: PropTypes.bool,
    color: PropTypes.string
};

export default CustomCreateableSelect;
