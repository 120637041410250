import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ClinicForm from "blikol-layouts/clinics/components/clinicForm";
import useClinicService from "service-hooks/useClinicService";
import Spinner from "../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

function EditClinic() {
  const navigate = useNavigate();
  const clinicHookService = useClinicService();
  const [loading, setLoading] = useState(true);
  const [clinicData, setClinicData] = useState({});
  const userRole = useSelector((state) => state.auth?.user?.role);
  const [clinicId, setClinicId] = useState(useParams().id);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

  const handleSubmit = (values) => {
    clinicHookService.update(values, clinicId).then((result) => {
      if (result) {
        setIsUpdateButtonDisabled(true);
        toast.success("Clinic updated successfully.");
        navigate(`/${userRole}/clinics`);
      }
    });
  };

  useEffect(() => {
    clinicHookService
      .clinic(clinicId)
      .then((result) => {
        if (result) {
          const clinic = result.data;
          const adminNote = clinic?.clinic_notes.find(obj => obj._type === 'admin');
          setLoading(false);
          setClinicId(clinic?.id);
          setClinicData({
            name: clinic?.name,
            email: clinic?.email,
            phone_number: clinic?.phone_number,
            address1: clinic?.address1,
            country: clinic?.country,
            state: clinic?.state,
            city: clinic?.city,
            _language: clinic?._language,
            postal_code: clinic?.postal_code,
            vat_number: clinic?.vat_number,
            tax_number: clinic?.tax_number,
            vat_payer: clinic?.vat_payer,
            note: clinic?.note,
            contact_person_info: {
              name: clinic?.contact_person_info?.name,
              phone_number: clinic?.contact_person_info?.phone_number,
            },
            clinic_notes_attributes: [
              {
                id: adminNote?.id,
                note: adminNote?.note

              }
            ],

          });
        } else {
          toast.error("No Clinic with this id");
          navigate(`/${userRole}/clinics`);
        }
      })
      .catch((error) => {
        // Handle the error (e.g., display an error message)
        console.error("Error fetching user data and attributes:", error);
      });
  }, []);

  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Clinics / Edit Clinic"} />
      {loading && <Spinner />}
      {!loading && (
        <ClinicForm
          clinicId={clinicId}
          clinicData={clinicData}
          heading={"Edit Clinic"}
          buttonText={"Update"}
          handleSubmit={handleSubmit}
          buttonDisabled={isUpdateButtonDisabled}
        />
      )}
    </DashboardLayout>
  );
}

export default EditClinic;
