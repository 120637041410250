export const countryToLocale = {
    "United States": "en-US",
    "United Kingdom": "en-GB",
    "France": "fr-FR",
    "Germany": "de-DE",
    "Japan": "ja-JP",
    "China": "zh-CN",
    "Russia": "ru-RU",
    "South Korea": "ko-KR",
    "Spain": "es-ES",
    "Italy": "it-IT",
    "Argentina": "es-AR",
    "Belgium": "nl-BE",
    "Switzerland": ["de-CH", "fr-CH", "it-CH"], // Switzerland has multiple locales
    "Austria": "de-AT",
    "Portugal": "pt-PT",
    "Norway": "nb-NO",
    "Denmark": "da-DK",
    "Finland": ["fi-FI", "sv-FI"], // Finland has multiple locales
    "Greece": "el-GR",
    "Poland": "pl-PL",
    "Turkey": "tr-TR",
    "Vietnam": "vi-VN",
    "Indonesia": "id-ID",
    "South Africa": "en-ZA",
    "Egypt": "ar-EG",
    "Pakistan": "ur-PK",
    "Ukraine": "uk-UA"
};

export const countryToDateFormat = {
    "United States": "m/d/Y",
    "United Kingdom": "d/m/Y",
    "France": "d/m/Y",
    "Germany": "d.m.Y",
    "Japan": "Y年m月d日",
    "China": "Y年m月d日",
    "Russia": "d.m.Y",
    "South Korea": "Y년 m월 d일",
    "Spain": "d/m/Y",
    "Italy": "d/m/Y",
    "Argentina": "d/m/Y",
    "Belgium": "d/m/Y",
    "Switzerland": {
        "de-CH": "d.m.Y",
        "fr-CH": "d.m.Y",
        "it-CH": "d/m/Y"
    },
    "Austria": "d.m.Y",
    "Portugal": "d/m/Y",
    "Norway": "d.m.Y",
    "Denmark": "d/m/Y",
    "Finland": {
        "fi-FI": "d.m.Y",
        "sv-FI": "Y-m-d"
    },
    "Greece": "d/m/Y",
    "Poland": "d.m.Y",
    "Turkey": "d.m.Y",
    "Vietnam": "d/m/Y",
    "Indonesia": "d/m/Y",
    "South Africa": "Y-m-d",
    "Egypt": "d/m/Y",
    "Pakistan": "d/m/Y",
    "Ukraine": "d.m.Y"
};

export function formatDate(inputDate, locationExt) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(locationExt || undefined, options);
}

export function formatTimestamp(timestamp, locationExt) {
    const date = new Date(timestamp);

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };
    const dateFormatted = new Intl.DateTimeFormat(locationExt || undefined, options).format(date);
    return dateFormatted

}

