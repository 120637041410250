/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';


// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import { useState, useEffect } from "react";
function Sidenav({ setActiveComponent, activeComponent }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const userRole = useSelector((state) => state.auth?.user?.role);
  const [sideNavItemsList, setSideNavItemsList] = useState()

  const sidenavItems = [
    { icon: <i className="ni ni-spaceship" />, label: "Diagnostic & App", href: "diagnostic" },
    { icon: <i className="ni ni-spaceship" />, label: "Packages", href: "packages" },
    { icon: <i className="ni ni-watch-time" />, label: "Feedback Questions", href: "questions" },
    { icon: <i className="ni ni-watch-time" />, label: "Financials", href: "credit-price" },
    { icon: <i className="ni ni-watch-time" />, label: "Session Settings", href: "session" },
    { icon: <i className="ni ni-watch-time" />, label: "Change Password", href: "change-password" },

  ];

  const sidenavItemsSecretary = [
    { icon: <i className="ni ni-watch-time" />, label: "Change Password", href: "change-password" },
  ];

  useEffect(() => {
    userRole === "secretary" ? (
      setSideNavItemsList(sidenavItemsSecretary)
    ) : (
      setSideNavItemsList(sidenavItems)
    );
  }, []);

  const renderSidenavItems = sideNavItemsList?.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;
    return (
      <ArgonBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <ArgonTypography
          component="a"
          variant="button"
          fontWeight={600}
          color={href === activeComponent ? "info" : "#1A1D1F"}
          onClick={() => setActiveComponent(href)}
          sx={({
            borders: { borderRadius },
            functions: { pxToRem, rgba },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            cursor: "pointer",
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: rgba(light.main, darkMode ? 0.1 : 1),
            },
          })}
        >
          {label}
        </ArgonTypography>
        <Divider sx={{ margin: 0 }} />
      </ArgonBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <ArgonBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </ArgonBox>
    </Card>
  );
}

Sidenav.propTypes = {
  setActiveComponent: PropTypes.func,
  activeComponent: PropTypes.string,
};
export default Sidenav;
