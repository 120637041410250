import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from "@mui/material/MenuItem";


// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import Breadcrumbs from "blikol-layouts/components/Breadcrumbs";
import DefaultItem from "examples/Items/DefaultItem";
import useAuthService from "service-hooks/useAuthService";
import userIcon from "../../../assets/images/icons/blikol/user.svg";
import notificationIcon from "../../../assets/images/icons/blikol/notification.svg";
import dropdownIcon from "../../../assets/images/icons/blikol/dropdown.svg";
import ConfirmationModal from "examples/ConfirmationModal"
import useClinicService from "service-hooks/clinic/useClinicService";
import { setUser } from "slices/authSlice";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,

} from "context";

// Images
import logoutIcon from "assets/images/icons/blikol/logout.svg";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function DashboardNavbar({ absolute, light, isMini, customRoutePath }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const authHookService = useAuthService();
  const userRole = useSelector((state) => state.auth?.user?.role);
  const dispatchUser = useDispatch()
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const clinicHookService = useClinicService()
  const [clinic, setClinic] = useState()
  const { t } = useTranslation()
  function formatString(inputString) {
    return inputString.replace(/_/g, ' ');
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    // window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    // return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  useEffect(() => {
    if (userRole === "clinic") {
      clinicHookService
        .clinic(user?.id)
        .then((result) => {
          if (result) {
            setClinic(result?.data);
            if (JSON.stringify(result.data) !== JSON.stringify(user)) {
              dispatchUser(setUser(result?.data));
            }
          } else {
            console.log("Data is undefined or incomplete.");
          }
        });
    }
  }, []); // Adding userRole to dependency array to re-run the effect when userRole changes
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = async () => {
    await authHookService.logout();
  };

  const menuItemStyle = {
    paddingLeft: "10px",
    color: '#11142D',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }

  // Render the notifications menu
  const renderMenu = () => (
    <>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 0 }}
      >
        {user?.role == "clinic" ? (
          <ArgonBox
            sx={{ padding: "30px 30px 0px 20px" }}
          >
            <ArgonBox
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                marginLeft: "10px"
              }}
            >
              <ArgonBox>
                <AccountCircleIcon sx={{ width: "36px", height: "36px", color: "#D9D9D999" }} />
              </ArgonBox>
              <ArgonBox
                sx={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <ArgonTypography
                  sx={{
                    color: '#11142D',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: "capitalise"
                  }}
                >
                  {clinic?.name}
                </ArgonTypography>
                <ArgonTypography
                  sx={{
                    color: '#808191',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textTransform: "capitalize"

                  }}
                >
                  {t(clinic?.role)}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
            <ArgonBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ArgonTypography
                sx={{
                  color: '#11142D',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginLeft: "10px"
                }}
                my={2}
              >
                {t("Credits Available")}
              </ArgonTypography>
              <ArgonBox
                sx={{
                  display: 'flex',
                  width: '78px',
                  height: '24px',
                  padding: '10px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '8px',
                  background: 'var(--Primary-Color, #0A9CC4)',
                  marginLeft: "10px"


                }}
                mb={2}
              >
                <ArgonTypography
                  sx={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '22px',
                  }}
                >
                  {user?.credits}
                </ArgonTypography>
              </ArgonBox>
              <ArgonTypography
                sx={{
                  color: '#11142D',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginLeft: "10px"
                }}
                mb={1}
              >
                {t("Support Email")}
              </ArgonTypography>
              <ArgonTypography
                component="a"
                href="mailto:clinics@blikol.com"
                sx={{
                  color: '#808191',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginLeft: "10px"
                }}
                mb={1}
              >
                clinics@blikol.com
              </ArgonTypography>
              <MenuItem
                sx={menuItemStyle}
                onClick={() => navigate("/clinic/plans")}

              >
                {t("Purchase Credits")}
              </MenuItem>
              <MenuItem
                sx={menuItemStyle}
                onClick={() => {
                  navigate("/clinic/payment-methods");
                }}
              >
                {t("Payment Method")}
              </MenuItem>
              <MenuItem
                sx={menuItemStyle}
                onClick={() => {
                  navigate("/clinic/change-password");
                }}
              >
                {t("Change Password")}
              </MenuItem>
              <MenuItem
                sx={menuItemStyle}
                onClick={() => {
                  navigate("/clinic/profile");
                }}
              >
                {t("Edit Profile")}
              </MenuItem>
              <MenuItem
                sx={menuItemStyle}
                onClick={() => {
                  navigate("/clinic/statistics");
                }}
              >
                {t("Statistics")}
              </MenuItem>
              <MenuItem
                sx={menuItemStyle}
                onClick={() => navigate("/clinic/change-language")}
              >
                {t("Change Language")}
              </MenuItem>
            </ArgonBox>
          </ArgonBox>
        )
          :
          (
            <ArgonBox sx={{ padding: "30px 30px 0px 20px" }}>
              <ArgonBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  marginLeft: "10px"
                }}
              >
                <ArgonBox>
                  <AccountCircleIcon sx={{ width: "36px", height: "36px", color: "#D9D9D999" }} />
                </ArgonBox>
                <ArgonBox
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <ArgonTypography
                    sx={{
                      color: '#11142D',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: "capitalize"
                    }}
                  >
                    {user?.name}
                  </ArgonTypography>
                  <ArgonTypography
                    sx={{
                      color: '#808191',
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      textTransform: "capitalize"

                    }}
                  >
                    {formatString(user?.role)}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
              <ArgonBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MenuItem
                  sx={menuItemStyle}
                  onClick={() => {
                    navigate(`/${user?.role}/settings`);
                  }}
                >
                  Settings
                </MenuItem>
              </ArgonBox>
            </ArgonBox>
          )
        }
        <ArgonBox sx={{ padding: "0px 30px 20px 20px" }}>
          <MenuItem
            sx={{
              display: "flex",
              paddingLeft: "10px",
              alignItems: "center",
              color: "red",
              '&:hover': {
                backgroundColor: '#e0e0e0',
                color: "red !important"
              },
            }}
            onClick={() => setShowConfirmationModal(true)}
          >
            {t("Logout")}
          </MenuItem>
        </ArgonBox>
      </Menu >
    </>

  );

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => ({
          ...navbar(theme, { transparentNavbar, absolute, light }),
          pb: 0, // Adds a bottom padding of 0
        })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme, { navbarType })}>
          <ArgonBox
            color={light && transparentNavbar ? "white" : "dark"}
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={transparentNavbar ? light : false}
            />
            {/* <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon> */}
            {/* <ArgonTypography
              variant="text"
              fontWeight="medium"
              color={light && transparentNavbar ? "white" : "dark"}
              sx={{ fontSize: "14px", marginLeft: "10px" }}
            >
              {customRoutePath}
            </ArgonTypography> */}
          </ArgonBox>
          {isMini ? null : (
            <ArgonBox sx={(theme) => navbarRow(theme, { isMini })}>
              <ArgonBox
                color={light ? "white" : "inherit"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <IconButton
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                  sx={{ fontSize: "4rem", color: "secondary" }}
                >
                  <ArgonBox component="img" src={userIcon} alt="user" width="2.5rem" mr={1} />
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color={light && transparentNavbar ? "white" : "dark"}
                  >
                    {user?.name || user?.email}
                  </ArgonTypography>
                  <ArgonBox component="img" src={dropdownIcon} alt="dropdown" ml={2} width="0.6rem" />
                </IconButton>{" "}
                <IconButton
                  size="small"
                  color={light && transparentNavbar ? "white" : "dark"}
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
                </IconButton>

                {renderMenu()}
              </ArgonBox>
            </ArgonBox>
          )}
        </Toolbar>
      </AppBar>
      <ConfirmationModal
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleLogout}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Logout")}
        confirmButtonColor={"red"}
        modalHeadingText={t("Logout")}
        confirmationText={t("Are you sure you want to logout?")}
      />
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
  customRoutePath: "",
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  customRoutePath: PropTypes.string,
};

export default DashboardNavbar;
