/* eslint-disable react/prop-types */
import React from "react";
// Argon Dashboard 2 PRO MUI example components
import { useSelector, useDispatch } from "react-redux";
import ArgonBox from "components/ArgonBox";
import useClinicService from "service-hooks/useClinicService";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import "../../style.css";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import noteAddIcon from "../../../../assets/images/icons/blikol/actions/note-add.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../components/Spinner";
import AddModal from "../AddModal";
import useExportCSVService from "service-hooks/useExportCSVService";
import notesPresentIcon from "assets/images/icons/admin/view-note.svg";
import ViewNotes from "../ViewNotesModal";
import useNoteService from "service-hooks/useNoteService";
import input from "assets/theme/components/form/input";
import PropTypes from "prop-types";
import select from "assets/theme/components/form/select";
import ConfirmationModal from "examples/ConfirmationModal";
import useSettingService from "service-hooks/useSettingService";
import { formatName, formatEmail, isPositiveInteger } from "parsers/formatParser";
import AddCreditModal from "../AddCreditModal";
function List({ status, setPageNo, pageNo }) {
  const userRole = useSelector((state) => state.auth?.user?.role);
  const user = useSelector((state) => state.auth?.user);
  const [data, setData] = useState({ clinicsData: [] });
  const navigate = useNavigate();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [listOfCountries, setListOfCountries] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({ Country: "", City: "" });
  const [listOfCities, setListOfCities] = useState([]);
  const clinicHookService = useClinicService();
  const [loading, setLoading] = useState(true);
  const [openCreditsModal, setOpenCreditsModal] = useState(false);
  const [openBonusModal, setOpenBonusModal] = useState(false);
  const [openViewNotesModal, setOpenViewNotesModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [checkboxState, setCheckboxState] = useState(true);
  const [clinicData, setClinicData] = useState();
  const [creditPriceDefault, setCreditPriceDefault] = useState(false);
  const [clinicId, setClinicId] = useState();
  const exportCSVServiceHook = useExportCSVService();
  const [notes, setNotes] = useState();
  const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const noteHookService = useNoteService();
  const [noteData, setNoteData] = useState();
  const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false);
  const settingHookService = useSettingService();
  const [defaultCreditPrice, setDefaultCreditPrice] = useState();
  const [viewId, setViewId] = useState();
  useMemo(() => {
    clinicHookService.countries().then((result) => {
      if (result) {
        setListOfCountries(result);
      }
    });
    clinicHookService.cities().then((result) => {
      if (result) {
        setListOfCities(result);
      }
    });
    settingHookService.settings().then((result) => {
      if (result) {
        const creditData = result.data.credit_price;
        setDefaultCreditPrice(creditData);
      } else {
        console.log("Unable to fetch settings");
      }
    });
  }, [pageRefresh]);

  useEffect(() => {
    clinicHookService
      .clinics(
        pageNo,
        entriesPerPage,
        selectedOptions["Country"],
        search,
        selectedOptions["City"],
        status
      )
      .then((result) => {
        if (result && result.clinicsData && result.clinicsMeta) {
          setData(result);
          setLoading(false);
          setTotalEntries(result?.clinicsMeta?.total_count);
          setTotalPages(result?.clinicsMeta?.total_pages);
        } else {
          console.log("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        // Handle the error (e.g., display an error message)
        console.error("Error fetching user data and attributes:", error);
      });
  }, [
    search,
    entriesPerPage,
    pageNo,
    pageRefresh,
    selectedOptions["Country"],
    selectedOptions["City"],
    search,
    status,
  ]);

  const handleFilterChange = (filterName, selectedValue) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: selectedValue,
    }));
  };

  const handleEdit = (id) => navigate(`/${userRole}/clinics/edit/${id}`);

  const handleView = (id) => {
    navigate(`/${userRole}/clinics/view/${id}`);
  };
  const handleEditNote = () => {
    setOpenViewNotesModal(false);
    setOpenEditNoteModal(true);
  };

  const handleFilledEditNote = () => {
    setOpenViewNotesModal(false);
    const updatedValues = {
      ...noteData,
      note: inputValue,
    };
    const response = noteHookService.update(updatedValues, noteData?.id);
    if (response) {
      toast.success("Note updated successfully");
      setInputValue("");
      setOpenEditNoteModal(false);
    } else {
      toast.error("Failed to update note");
    }
  };

  const handleOutlinedEditNote = () => {
    setOpenEditNoteModal(false);
    setInputValue("");
  };

  const handleNoteAdd = (id) => {
    setInputValue("");
    setClinicId(id);
    setOpenAddNoteModal(true);
  };

  const handleFilledAddNote = () => {
    noteHookService
      .create(inputValue, clinicId)
      .then((response) => {
        if (response) {
          toast.success("Note added successfully");
          setInputValue("");
          setOpenAddNoteModal(false);
          setPageRefresh(!pageRefresh);
        } else {
          toast.error("Failed to add note");
        }
      })
      .catch((error) => {
        console.error("Error while adding note:", error);
        toast.error("An error occurred while adding note");
      });
  };

  const handleOutlinedAddNote = (id) => {
    setOpenAddNoteModal(false);
    setInputValue("");
  };

  const handleViewNotes = (id) => {
    setLoading(true);
    setClinicId(id);
    clinicHookService
      .clinic(id)
      .then((result) => {
        setLoading(false);
        if (result) {
          setOpenViewNotesModal(true);
          setInputValue(result?.data?.clinic_notes[0]?.note);
          setNoteData(result?.data?.clinic_notes[0]);
          setNotes(result?.data?.clinic_notes);
        } else {
          console.log("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        // Handle the error (e.g., display an error message)
        console.error("Error fetching user data and attributes:", error);
      });
  };

  const handleAddBonus = (id) => {
    clinicHookService.clinic(id).then((result) => {
      if (result) {
        setClinicData(result?.data);
        setSecondInputValue(result?.data?.credits);
        setClinicId(result?.data?.id);
      }
    });
    setOpenBonusModal(true);
  };

  const handleFilledBonus = async () => {
    try {
      setOpenCreditsModal(false);
      const inputIntBonus = parseInt(inputValue);
      const inputIntUpdated = parseInt(secondInputValue);
      const credits = { bonus: inputIntBonus, updated: inputIntUpdated };
      const response = await clinicHookService.addBonus(clinicId, credits);
      if (response) {
        toast.success("Credits updated successfully");
        setInputValue("");
        setOpenBonusModal(false);
        setPageRefresh(!pageRefresh);
      } else {
        toast.error("Failed to add credit. Please try again.");
      }
    } catch (error) {
      console.error("Error while adding bonus:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleOutlinedBonus = () => {
    setOpenBonusModal(false);
    setInputValue("");
    setSecondInputValue("");
  };

  const handleCreditsPrice = async (id) => {
    try {
      setLoading(true);
      const result = await clinicHookService.clinic(id);
      if (result) {
        setLoading(false);
        setOpenCreditsModal(true);
        setClinicData(result?.data);
        setCreditPriceDefault(result?.data?.default_credit_price_active);
        setClinicId(result?.data?.id);
        if (result?.data?.default_credit_price_active) {
          setInputValue(defaultCreditPrice);
        } else {
          setInputValue(result?.data?.credit_price);
        }
      }
    } catch (error) {
      // Handle error here
      console.error("Error:", error);
    }
  };

  const handleFilledCredits = async () => {
    try {
      setOpenBonusModal(false);
      let updatedValues = {};
      if (checkboxState) {
        updatedValues = {
          ...clinicData,
          default_credit_price_active: true,
        };
      } else {
        updatedValues = {
          ...clinicData,
          credit_price: parseInt(inputValue),
          default_credit_price_active: false,
        };
      }
      const response = await clinicHookService.update(updatedValues, clinicId);
      if (response) {
        toast.success(`Credits added to clinic ${clinicId} successfully`);
        handleOutlinedCredits();
        setInputValue("");
      } else {
        toast.error("Failed to update clinic information");
      }
    } catch (error) {
      console.error("Error while updating clinic information:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleOutlinedCredits = () => {
    setOpenCreditsModal(false);
    setOpenCreditsModal(false);
    setInputValue("");
  };

  const handleOutlineRemove = () => {
    setOpenConfirmRemoveModal(false);
  };

  const handleFilledRemove = () => {
    clinicHookService
      .destroy(clinicId)
      .then(() => {
        toast.success("Clinic deleted successfully.");
        setPageRefresh(!pageRefresh);
        setOpenConfirmRemoveModal(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleSecondInputChange = (value) => {
    setSecondInputValue(value);
  };

  const handleCheckboxStateChange = (newCheckboxState) => {
    setCheckboxState(newCheckboxState);
    if (newCheckboxState) {
      setInputValue(defaultCreditPrice);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      // Fetch clinic data
      const result = await clinicHookService.clinic(id);
      if (result) {
        const updatedValues = {
          ...result.data, // Copy the existing values
          status: newStatus, // Set the status to the desired value
        };

        const response = await clinicHookService.update(updatedValues, id);
        if (response) {
          toast.success(
            `Clinic ${newStatus === "inactive" ? "de-activated" : "activated"} successfully.`
          );
          navigate(`/${userRole}/clinics`);
        } else {
          toast.error(`Failed to update clinic status to ${newStatus}.`);
        }
      } else {
        toast.error("No Clinic with this id");
        navigate(`/${userRole}/clinics`);
      }
    } catch (error) {
      console.error(`Error updating clinic status to ${newStatus}:`, error);
      toast.error(`Error updating clinic status to ${newStatus}.`);
    }
    setPageRefresh(!pageRefresh);
  };

  const handleInactive = async (id) => {
    await handleStatusChange(id, "inactive");
  };

  // Example usage for reactivating
  const handleReactivate = async (id) => {
    await handleStatusChange(id, "active");
  };

  const handleResendCredntials = (id) => {
    clinicHookService
      .resendCredentails(id)
      .then((result) => {
        toast.success("Credentials resend successfully.");
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const handleRemove = (id) => {
    setOpenConfirmRemoveModal(true);
    setClinicId(id);
  };

  const handleAddClinic = () => navigate(`/${userRole}/clinics/new`);

  const handleExportCSV = () => {
    const pathname = `/administrator_apis/clinics/export?status=${status == "active" ? "" : status
      }&query=${search}&country=${selectedOptions["Country"]}&city=${selectedOptions["City"]}`;
    const filename = `admin_clinics`;
    exportCSVServiceHook.exportCSV(pathname, filename);
  };

  // Constants for filter and actions binded
  const filters = [
    {
      name: "City",
      action: (selectedValue) => handleFilterChange("City", selectedValue),
      listOfOptions: listOfCities?.cities?.data,
    },
    {
      name: "Country",
      action: (selectedValue) => handleFilterChange("Country", selectedValue),
      listOfOptions: listOfCountries?.countries?.data,
    },
  ];

  const threeDotsPending = [
    {
      type: " Resend Credentials",
      action: handleResendCredntials,
    },
    {
      type: "Edit",
      action: handleEdit,
    },
    {
      type: "Remove",
      action: handleRemove,
      style: {
        color: "red",
      },
    },
  ];

  const threeDotsActive = [
    {
      type: "Edit",
      action: handleEdit,
    },
    {
      type: "Add Credit",
      action: handleAddBonus,
    },
    {
      type: "Change Credit Price",
      action: handleCreditsPrice,
    },
    {
      type: "Deactivate",
      action: handleInactive,
      style: {
        color: "red",
      },
    },
  ];

  const threeDotsInactive = [
    {
      type: "Edit",
      action: handleEdit,
    },
    {
      type: "Reactivate",
      action: handleReactivate,
    },
    {
      type: "Remove",
      action: handleRemove,
    },
  ];

  const customIcons = [
    {
      action: handleNoteAdd,
      icon: <img src={noteAddIcon} alt="Note" />,
    },
  ];

  const customIconsNotesPresent = [
    {
      action: handleViewNotes,
      icon: <img src={notesPresentIcon} alt="NotePresent" />,
    },
  ];

  const statusColumn = (status) => {
    return (
      <>
        <div className={`status-${status}`}>{status}</div>
      </>
    );
  };

  const clinicsRowsData = data.clinicsData.map((clinic, index) => ({
    ...clinic,
    id: clinic?.id,
    rawStatus: clinic?.status, // Store raw status text for sorting
    status: statusColumn(clinic?.status), // Use statusColumn for rendering
    name: formatName(clinic?.name),
    city: formatName(clinic?.city),
    action: (
      <ActionMenu
        threeDots={
          userRole === "admin" || userRole === "super_admin"
            ? clinic.status === "active"
              ? threeDotsActive
              : clinic.status === "pending"
                ? threeDotsPending
                : threeDotsInactive
            : null
        }
        row={clinic}
        customIcons={
          clinic.clinic_notes.map((note) => note._type).includes("admin")
            ? customIconsNotesPresent
            : customIcons
        }
        key={index + 1}
      />
    ),
  }));

  const canAdd =
    userRole === "admin" || userRole === "super_admin"
      ? {
        name: "Add Clinic",
        action: handleAddClinic,
      }
      : false;

  // Data table
  const tableData = {
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "country", accessor: "country" },
      { Header: "city", accessor: "city" },
      { Header: "credits available", accessor: "credits" },
      {
        Header: "Status",
        accessor: "rawStatus", // Use raw status text for sorting
        Cell: ({ value }) => statusColumn(value) // Use statusColumn for rendering
      },
      { Header: "no. of patients", accessor: "no_of_patients" },
      { Header: "action", accessor: "action" },
    ],
    rows: clinicsRowsData,
  };

  return (
    <>
      <Card p={1}>
        <ArgonBox>
          {loading && <Spinner />}
          {!loading && (
            <DataTable
              canExportCSV={{
                name: "Export as CSV",
                action: handleExportCSV,
              }}
              canAdd={canAdd}
              canSearch
              canFilter
              filters={filters}
              dataTableName={"Clinics"}
              entriesPerPage={entriesPerPage}
              setSearch={setSearch}
              search={search}
              totalPages={totalPages}
              setEntriesPerPage={setEntriesPerPage}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalEntries={totalEntries}
              table={tableData}
              viewEntry={handleView}
              setViewId={setViewId}
              clickableRecords={true}
            />
          )}
        </ArgonBox>
      </Card>
      {openCreditsModal && (
        <AddModal
          open={openCreditsModal}
          headerContent="Add Credit Price"
          subHeaderContent="Credit Price"
          filledBtnText="Add"
          filledBtnAction={handleFilledCredits}
          outlinedBtnText="Cancel"
          outlinedBtnAction={handleOutlinedCredits}
          inputValue={inputValue} // Pass inputValue as a prop
          setInput={handleInputChange} // Pass setInput function as a prop
          hasCheckbox={true}
          checkBoxText="Use default price"
          setCheckboxState={handleCheckboxStateChange}
          initialCheckBoxState={creditPriceDefault}
        />
      )}

      {/* this is the bonus modal and the name of this field is bonus throughout the project we are just changing the label to credit */}
      {openBonusModal && (
        <AddCreditModal
          open={openBonusModal}
          headerContent="Add Credit"
          subHeaderContent="Extra Credit"
          hasSecondInput={true}
          secondSubHeaderContent="Current Credits"
          secondInputValue={secondInputValue}
          setSecondInput={handleSecondInputChange}
          filledBtnText="Add"
          filledBtnAction={handleFilledBonus}
          outlinedBtnText="Cancel"
          outlinedBtnAction={handleOutlinedBonus}
          inputValue={inputValue} // Pass inputValue as a prop
          setInput={handleInputChange} // Pass setInput function as a prop
        />
      )}
      {openEditNoteModal && (
        <AddModal
          open={openEditNoteModal}
          headerContent="Edit Note"
          subHeaderContent="Note"
          filledBtnText="Update"
          filledBtnAction={handleFilledEditNote}
          outlinedBtnText="Cancel"
          outlinedBtnAction={handleOutlinedEditNote}
          inputValue={inputValue} // Pass inputValue as a prop
          setInput={handleInputChange} // Pass setInput function as a prop
        />
      )}
      {openAddNoteModal && (
        <AddModal
          open={openAddNoteModal}
          headerContent="Add Note"
          subHeaderContent="Note"
          filledBtnText="Add"
          filledBtnAction={handleFilledAddNote}
          outlinedBtnText="Cancel"
          outlinedBtnAction={handleOutlinedAddNote}
          inputValue={inputValue} // Pass inputValue as a prop
          setInput={handleInputChange} // Pass setInput function as a prop
        />
      )}
      {openViewNotesModal && (
        <ViewNotes
          open={openViewNotesModal}
          data={notes}
          onClose={() => {
            setOpenViewNotesModal(false);
            setNotes();
          }}
          handleEditNote={handleEditNote}
        />
      )}
      {openConfirmRemoveModal && (
        <ConfirmationModal
          open={openConfirmRemoveModal}
          onClose={handleOutlineRemove}
          onConfirm={handleFilledRemove}
          cancelButtonText={"Cancel"}
          confirmButtonText={"Remove"}
          confirmButtonColor={"red"}
          modalHeadingText={"Remove Clinic"}
          confirmationText={"Are you sure you want to remove this clinic?"}
        />
      )}
    </>
  );
}
List.propTypes = {
  status: PropTypes.string,
  setPageNo: PropTypes.func,
  pageNo: PropTypes.number
};
export default List;
