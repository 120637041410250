import React from "react";
import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

//routes
import { useNavigate, useLocation } from "react-router-dom";

//validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

//external components
import "react-country-state-city/dist/react-country-state-city.css";
import useClinicService from "service-hooks/useClinicService";
import "../style.css";
import { useSelector } from "react-redux";
import {
  GetCountries,
  GetState,
  GetCity, //async functions
} from "react-country-state-city";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ArgonSelect from "components/ArgonSelect";
import button from "assets/theme/components/button";
import ConfirmationModal from "examples/ConfirmationModal";
import Checkbox from '@mui/material/Checkbox';
import CreatableSelect from 'react-select/creatable';
import CustomCreateableSelect from "components/CustomCreateableSelect";
import { languageOptions } from "parsers/formatParser";
function clinicForm({ clinicId, clinicData, buttonText, heading, justifyContent, handleSubmit, buttonDisabled }) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.user?.role);
  const clinicHookService = useClinicService();
  const [countryid, setCountryid] = useState(0);
  const [stateId, setstateId] = useState(0);
  const [cityId, setcityId] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [initialValues, setInitialValues] = useState(clinicData);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [formValues, setFormValues] = useState()
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [contactPersonPhoneNumberError, setContactPersonPhoneNumberError] = useState(false)
  const [vatPayer, setVatPayer] = useState(clinicData?.vat_payer || false)
  const getValidationSchema = (vatPayer) => Yup.object({
    name: Yup.string().required("Clinic Name is required"),
    email: Yup.string().email("Invalid email address").required("Clinic Email is required"),
    phone_number: Yup.string().required("Phone number is required"),
    address1: Yup.string().required("Address1 is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    _language: Yup.string().required("Language is required"),
    note: Yup.string(),
    contact_person_info: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phone_number: Yup.string().required("Phone number is required"),
    }),
    vat_number: vatPayer
      ? Yup.string().required("VAT Number is required")
      : Yup.string().notRequired(),
    tax_number: Yup.string().required("Tax Number is required"),
    vat_payer: Yup.boolean(),
    clinic_notes_attributes: Yup.array().of(
      Yup.object().shape({
        note: Yup.string(),
      })
    ),
    postal_code: Yup.string().required("Postal code is required"),
  })

  const handleClose = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirm = (values) => {
    handleSubmit(values); // Submit the values
    setOpenConfirmModal(false); // Close the modal
  };


  const getDefaultValue = (fieldName) => {
    if (clinicId) {
      return { id: clinicId, name: initialValues[fieldName] };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetCountries();
        setCountriesList(response);
      } catch (e) {
        console.log("there seems to be an error", e);
      }
    };
    fetchData();
  }, []);
  const countryChange = async (value) => {
    try {
      setCountryid(value);
      const response = await GetState(value);
      response.length !== 0 ? setStateList(response) : setStateList(null);
      setCityList(null);
      setcityId("");
      setstateId("");
    } catch (e) {
      console.log("there seems to be an error", e);
    }
  };

  const stateChange = async (value) => {
    try {
      setstateId(value);
      const response = await GetCity(countryid, value);
      response.length !== 0 ? setCityList(response) : setCityList(null);
    } catch (e) {
      console.log("there seems to be an error", e);
    }
  };


  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <ArgonBox px={3} pt={3}>
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          {heading}
        </ArgonTypography>
      </ArgonBox>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(vatPayer)}
        onSubmit={(values) => {
          setOpenConfirmModal(true)
          setFormValues(values)
        }}
      >
        {(props) => (
          <Form>
            <Card
              id="clinic-info"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Clinic Info</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="clinic name"
                      placeholder="Alec Benjamin"
                      value={props.values.name}
                      name="name"
                      error={props.errors.name && props.touched?.name}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="clinic email"
                      placeholder="example@email.com"
                      name="email"
                      value={props.values.email}
                      error={props.errors.email && props.touched?.email}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        <>
                          Phone Number
                          <span style={{ color: 'red', marginLeft: "3px" }}>*</span>
                        </>
                      </ArgonTypography>
                    </ArgonBox>
                    <PhoneInput
                      name="clinic-phone-number"
                      international
                      value={props.values.phone_number}
                      onChange={(e) => {
                        props.setFieldValue("phone_number", e);
                        if (e === undefined || isValidPhoneNumber(e)) {
                          setPhoneNumberError(false);
                          props.setFieldError("phone_number", undefined);

                        } else {
                          setPhoneNumberError(true);
                          props.setFieldError("phone_number", "Phone number is invalid");

                        }
                      }}
                      className={(phoneNumberError) || (props?.errors?.phone_number && props?.touched?.phone_number) ? "error-phone-number " : "PhoneInput"}
                      rules={{ required: true }}
                    />
                    {((props?.errors?.phone_number && props?.touched?.phone_number) || phoneNumberError) && (
                      <ArgonBox mt={0.75}>
                        <ArgonTypography component="div" variant="caption" color="error">
                          {props.values.phone_number === "" ? 'Phone number is required' : 'Phone number is invalid'}
                        </ArgonTypography>
                      </ArgonBox>
                    )}
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="contact-personal-info"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Contact Person Info</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="name"
                      name="contact_person_info.name"
                      placeholder="Alec Benjamin"
                      value={props.values?.contact_person_info?.name}
                      error={
                        props.errors.contact_person_info?.name &&
                        props.touched?.contact_person_info?.name
                      }
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        <>
                          Phone Number
                          <span style={{ color: 'red', marginLeft: "3px" }}>*</span>
                        </>
                      </ArgonTypography>
                    </ArgonBox>

                    <PhoneInput
                      name="contact-person-phone-number"
                      international
                      value={props.values?.contact_person_info?.phone_number}
                      onChange={(e) => {
                        props.setFieldValue("contact_person_info.phone_number", e);
                        if (e === undefined || isValidPhoneNumber(e)) {
                          setContactPersonPhoneNumberError(false);
                          props.setFieldError("contact_person_info.phone_number", undefined);

                        } else {
                          setContactPersonPhoneNumberError(true);
                          props.setFieldError("contact_person_info.phone_number", "Phone number is invalid");

                        }
                      }}
                      className={(contactPersonPhoneNumberError) || (props?.errors?.contact_person_info?.phone_number && props?.touched?.contact_person_info?.phone_number) ? "error-phone-number " : "PhoneInput"}
                    />
                    {((props?.errors?.contact_person_info?.phone_number && props?.touched?.contact_person_info?.phone_number) || contactPersonPhoneNumberError) && (
                      <ArgonBox mt={0.75}>
                        <ArgonTypography component="div" variant="caption" color="error">
                          {props?.values?.contact_person_info?.phone_number === '' ? 'Phone number is required' : 'Phone number is invalid'}
                        </ArgonTypography>
                      </ArgonBox>
                    )}
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="clinic address"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Clinic Address</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      label="address 1"
                      placeholder="eg. Street 120"
                      value={props.values.address1}
                      name="address1"
                      error={props.errors.address1 && props.touched?.address1}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} pt={1.5} mb={1.5}>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Country <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonSelect
                      options={countriesList.map((option) => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      placeholder="Select Country"
                      defaultValue={
                        props.values.country
                          ? { label: props.values.country, value: props.values.country }
                          : null
                      }
                      onChange={(selectedOption) => {
                        props.setFieldValue(
                          "country",
                          selectedOption ? selectedOption.label : null
                        );
                        props.setFieldValue("state", null);
                        props.setFieldValue("city", null);
                        if (selectedOption.label === "Spain") {
                          setVatPayer(prevVatPayer => {
                            const newVatPayer = !prevVatPayer;
                            props.setFieldValue("vat_payer", newVatPayer);
                            return newVatPayer;
                          });
                        }
                        setStateList(null);
                        countryChange(selectedOption.value);
                      }}
                      error={props.errors.country && props.touched?.country}
                    />
                    {props.errors.country && props.touched?.country && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.country}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        State/Province <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    {stateList !== null ? (
                      <ArgonSelect
                        options={stateList.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))}
                        defaultValue={
                          props.values.state
                            ? { label: props.values.state, value: props.values.state }
                            : null
                        }
                        onChange={async (selectedOption) => {
                          props.setFieldValue(
                            "state",
                            selectedOption ? selectedOption.label : null
                          );
                          stateChange(selectedOption.value);
                          props.setFieldValue("city", null);
                          setCityList(null);
                        }}
                        error={props.errors.state && props.touched?.state}
                        placeholder="Select State"
                      />
                    ) : (
                      <ArgonInput
                        defaultValue={props.values.state}
                        onChange={(e) => {
                          props.setFieldValue("state", e.target.value);
                          props.setFieldValue("city", null);
                        }}
                        placeholder="e.g Punjab"
                        error={props.errors.state && props.touched?.state}
                      ></ArgonInput>
                    )}
                    {props.errors.state && props.touched?.state && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.state}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        mb={1}
                      >
                        City <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    {cityList !== null ? (
                      <CustomCreateableSelect
                        options={cityList.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))}
                        onChange={(selectedOption) => {
                          props.setFieldValue("city", selectedOption ? selectedOption.label : null);
                        }}
                        error={props.errors.city && props.touched?.city}
                        defaultValue={
                          props.values.city
                            ? { label: props.values.city, value: props.values.city }
                            : null
                        }
                        placeholder="Select City"
                      />
                    ) : (
                      <ArgonInput
                        placeholder="e.g Lahore"
                        error={props.errors.city && props.touched?.city}
                        onChange={(e) => {
                          props.setFieldValue("city", e.target.value);
                        }}
                        defaultValue={props.values.city}
                      ></ArgonInput>
                    )}
                    {props.errors.city && props.touched?.city && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.city}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormField
                      label="post code"
                      placeholder="eg. 54770"
                      value={props.values.postal_code}
                      name="postal_code"
                      error={props.errors.postal_code && props.touched?.postal_code}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={1}
                      ml={0.5}
                    >
                      Language  <span style={{ color: "red" }}>*</span>
                    </ArgonTypography>
                    <ArgonSelect
                      placeholder="e.g English"
                      options={languageOptions}
                      onChange={(selectedOption) => {
                        props.setFieldValue("_language", selectedOption ? selectedOption.value : null);
                      }}
                      defaultValue={
                        props.values._language
                          ? {
                            label: languageOptions.find(option => option.value === props.values._language)?.label || '',
                            value: props.values._language,
                          }
                          : null
                      }
                      error={props.errors._language && props.touched?._language}
                    />
                    {props.errors._language && props.touched?._language && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors._language}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="financial"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Financials</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      label="Tax Number"
                      placeholder="TX1D23FE2"
                      name="tax_number"
                      value={props.values.tax_number}
                      error={props.errors.tax_number && props.touched?.tax_number}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ArgonBox mb={3.3}>
                      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Vat Payer  <span style={{ color: 'red' }}>*</span>
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonBox sx={{ border: "0.0625rem solid #d2d6da", padding: "8px 12px", borderRadius: "0.5rem" }}>
                        <ArgonBox sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                          <input
                            type="checkbox"
                            className="vat-payer-checkbox"
                            onChange={() => {
                              setVatPayer((prevVatPayer) => {
                                const newVatPayer = !prevVatPayer;
                                props.setFieldValue("vat_payer", newVatPayer);

                                if (!newVatPayer) {
                                  props.setFieldError("vat_number", "");
                                  props.setFieldTouched("vat_number", false);
                                }

                                return newVatPayer;
                              });
                            }}

                            checked={vatPayer}
                          />
                          <ArgonTypography
                            sx={{
                              color: 'var(--Text, #1A1D1F)',
                              fontFeatureSettings: "'clig' off, 'liga' off",
                              fontFamily: "Open Sans",
                              fontSize: 14,
                              fontStyle: 'normal',
                              fontWeight: 400,

                            }}
                          >
                            VAT Payer
                          </ArgonTypography>
                        </ArgonBox>

                      </ArgonBox>
                    </ArgonBox>

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      label="Vat Number"
                      placeholder="VS12CDE2"
                      name="vat_number"
                      value={props.values.vat_number}
                      error={props.errors.vat_number && props.touched?.vat_number}
                      onChange={props.handleChange}
                      requiredField={true}
                      disabled={!vatPayer}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="note"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                marginTop: "150p",
              }}
            >
              <ArgonBox px={3} pt={3} pb={1.5}>
                <ArgonTypography variant="h5">Note</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3} mb={1.5}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <ArgonBox>
                      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <ArgonTypography
                          sx={{
                            color: "var(--Default, #172B4D)",

                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "150%",
                          }}
                        >
                          Add Note
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonInput
                        placeholder="Type here..."
                        name={(userRole === "admin" || userRole === "super_admin") ? "clinic_notes_attributes[0].note" : "note"}
                        multiline
                        rows={5}
                        value={userRole === "admin" || userRole === "super_admin" ? props.values.clinic_notes_attributes[0].note : props.values.note}
                        onChange={props.handleChange}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <ArgonBox
              p={3}
              sx={{
                display: "flex",
                justifyContent: justifyContent,
              }}
            >
              <ArgonButton
                type="submit"
                fullWidth
                variant="contained"
                color={"info"}
                sx={{
                  display: "flex",
                  width: "124px",
                  padding: " 10px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
                disabled={buttonDisabled}
              >
                {buttonText}
              </ArgonButton>
            </ArgonBox>
            {openConfirmModal && (
              <ConfirmationModal
                open={openConfirmModal}
                onClose={handleClose}
                onConfirm={() => {
                  handleSubmit(formValues)
                  setOpenConfirmModal(false)
                }}
                cancelButtonText={"Continue Editing"}
                confirmButtonText={buttonText === "Add" ? "Send Invite" : "Save Changes"}
                modalHeadingText={buttonText === "Add" ? "Invite Clinic" : "Edit Clinic"}
                confirmationText={buttonText === "Add" ? "Are you sure you want to send invitation to the clinic?" : "Are you sure you want to save the changes?"}
              />
            )}

          </Form>
        )}
      </Formik>
    </>
  );
}

clinicForm.defaultProps = {
  clinicId: "",
  clinicData: {
    name: "",
    email: "",
    phone_number: "",
    address1: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    _language: "",
    vat_number: "",
    tax_number: "",
    vat_payer: false,
    note: "",
    clinic_notes_attributes: [{
      note: ""
    }],
    contact_person_info: {
      name: "",
      phone_number: "",
    },
  },
  buttonText: "",
  heading: "",
  justifyContent: "start",
  handleSubmit: "",
  buttonDisabled: false
};

export default clinicForm;
