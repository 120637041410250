
export function formatName(str) {
    if (!str) return ""; // Return an empty string if str is falsy (empty or undefined)

    // Split the string into an array of words
    let words = str.split(" ");

    // Capitalize the first letter of each word
    let capitalizedWords = words.map(word => {
        // If word starts with an apostrophe, return it as it is
        if (word.startsWith("'")) return word;
        // Capitalize the first letter otherwise
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back into a sentence
    return capitalizedWords.join(" ");
}

export function formatEmail(str) {
    if (!str) return ""; // Return empty string if input is empty or null

    // Lowercase the first character and concatenate it with the rest of the string
    return str.charAt(0).toLowerCase() + str.slice(1);
}
export function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
}
export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };
    const dateFormatted = new Intl.DateTimeFormat(undefined, options).format(date);
    return dateFormatted

}

export function amount(value) {
    if (value) {
        return `€${value}`;
    }
    else {
        return ""
    }
}

export function isPositiveInteger(value) {
    return Number.isInteger(value) && value >= 0;
}

export function formatVatTotal(number) {
    // Check if the number is an integer
    if (Number.isInteger(number)) {
        return number.toString(); // Return the number as a string without appending .00
    } else {
        return number.toFixed(2); // Return the number with two decimal places
    }
};

export const languageOptions = [
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
    { value: "de", label: "Deutsch" },
    { value: "it", label: "Italiano" },
    { value: "fr", label: "Français" }, // fixed the duplicate "de" value for French
];

export const languageMapping = {
    "en": "English",
    "es": "Español",
    "de": "Deutsch",
    "it": "Italiano",
    "fr": "Français"
}

