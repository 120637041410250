import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Settings page components
// import BaseLayout from "layouts/pages/account/components/BaseLayout";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonTypography from "components/ArgonTypography";

import Sidenav from "blikol-layouts/settings/components/Sidenav";
import Diagnostic from "blikol-layouts/settings/components/Diagnostic";
import Session from "blikol-layouts/settings/components/Session";
import PackageList from "blikol-layouts/settings/components/Package";
import Questions from "blikol-layouts/settings/components/Questions";
import CreditPrice from "blikol-layouts/settings/components/CreditPrice"
import ResetPassword from "blikol-layouts/components/ResetPassword"
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';




function Settings() {
  // const [activeComponent, setActiveComponent] = useState("diagnostic");
  const navigate = useNavigate();
  const location = useLocation()
  const userRole = useSelector((state) => state.auth?.user?.role);
  const stateValue = location?.state?.value || null;
  const [activeComponent, setActiveComponent] = useState("diagnostic");

  const addPackage = () => {
    navigate(`/${userRole}/settings/package/new`);
  };

  useEffect(() => {
    if (userRole === "secretary") {
      setActiveComponent("change-password");
    } else {
      if (stateValue === "packages") {
        setActiveComponent("packages");
      } else {
        setActiveComponent("diagnostic");
      }
    }
  }, [stateValue]);

  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Settings"} />

      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ sm: "row" }}
        pr={{ lg: 8, xl: 10, xxl: 12 }}
        mt={5}
      >
        <ArgonTypography
          variant="h3"
          color="dark"
          mt={5}
          sx={{
            fontSize: "20px",
            letterSpacing: "-0.8px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "50%",
          }}
        >
          Settings
        </ArgonTypography>
        {activeComponent === "packages" && (
          <ArgonBox ml={{ xs: 0, sm: "auto" }}>
            <ArgonButton color="info" sx={{ px: 2 }} onClick={addPackage}>
              Add Package
            </ArgonButton>
          </ArgonBox>
        )}
      </ArgonBox>
      <ArgonBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav activeComponent={activeComponent} setActiveComponent={setActiveComponent} />
          </Grid>

          <Grid item xs={12} lg={9}>
            <ArgonBox mb={3}>
              <Grid container spacing={3}>
                {
                  userRole === "secretary" ? (
                    <Grid item xs={12} lg={11}>
                      {activeComponent === "change-password" && <ResetPassword />}
                    </Grid>
                  ) : (
                    <Grid item xs={12} lg={11}>
                      {activeComponent === "diagnostic" && <Diagnostic />}
                      {activeComponent === "session" && <Session />}
                      {activeComponent === "packages" && <PackageList />}
                      {activeComponent === "questions" && <Questions />}
                      {activeComponent === "credit-price" && <CreditPrice />}
                      {activeComponent === "change-password" && <ResetPassword />}
                    </Grid>
                  )
                }
              </Grid>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default Settings;
